// Projects.js
import React, { useEffect, useState } from 'react';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AddIcon from '@mui/icons-material/Add';
import { blueGrey } from '@mui/material/colors';
import { IconButton, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ProjectCreator from './ProjectCreator';
import AlertDialog from '../AlertDialog';

export function Projects() {
    const [editing, setEditing] = useState(false);
    const [projects, setProjects] = useState([]);
    const [activeProject, setActiveProject] = useState(null);
    const [currentProject, setCurrentProject] = useState(null);
    const [creatorOpen, setCreatorOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const navigate = useNavigate();

    const showConfirmation = (project) => {
        setAlertOpen(true);
        setCurrentProject(project);
    };

    const handleAgree = () => {
        deleteProject(currentProject);
        setAlertOpen(false);
    };

    const handleDisagree = () => {
        setAlertOpen(false);
    };

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await axios.get('/api/dev/projects');
                setProjects(response.data);
            } catch (error) {
                console.error('Error fetching projects:', error);
            }
            try {
                const response = await axios.get('/api/dev/projects/active');
                setActiveProject(response.data);
            } catch (error) {
                console.error('Error fetching active project:', error);
            }
        };
        fetchProjects();
    }, []);

    const handleCreatorClose = () => {
        setCreatorOpen(false);
        setEditing(false);
    };

    const addProject = async (newProject) => {
        try {
            const response = await axios.post('/api/dev/projects/add', { project: newProject });
            setProjects(response.data);
        } catch (error) {
            console.error('Error adding project:', error);
        }
    };

    const handleActivateProject = async (id) => {
        try {
            const response = await axios.post('/api/dev/projects/active', { id: id });
            setActiveProject(response.data);
            navigate(0);
        } catch (error) {
            console.error('Error activating project:', error);
        }
    };

    const deleteProject = async (project) => {
        try {
            const response = await axios.post('/api/dev/projects/delete', { id: project.id });
            setProjects(response.data);
            setActiveProject('');
        } catch (error) {
            console.error('Error deleting project:', error);
        }
    };

    const updateProject = async (i, updatedProject) => {
        try {
            const response = await axios.post('/api/dev/projects/update', { project: updatedProject });
            setProjects(response.data);
        } catch (error) {
            console.error('Error updating project:', error);
        }
    };

    return (
        <React.Fragment>
            {projects.map((project, i) => (
                <ListItemButton
                    key={project.id}
                    onClick={(e) => { e.preventDefault(); handleActivateProject(project.id); }}
                    sx={{
                        backgroundColor: blueGrey[300],
                        opacity: project.id === activeProject ? '100%' : '50%',
                        paddingRight: "5px"
                    }}
                >
                    <Tooltip title={project.title}>
                        <ListItemIcon><ListAltIcon /></ListItemIcon>
                    </Tooltip>
                    <Tooltip title={project.title}>
                        <ListItemText primaryTypographyProps={{ style: { fontWeight: 'bold' }, noWrap: true }} primary={project.title} />
                    </Tooltip>
                    <IconButton
                        onClick={(e) => { e.preventDefault(); e.stopPropagation(); setEditing(true); setCreatorOpen(true); }}
                        disabled={project.id !== activeProject}
                        sx={{ display: project.id !== activeProject ? "none" : null, padding: "5px" }}
                    >
                        <SettingsIcon />
                    </IconButton>
                    <IconButton onClick={(e) => { e.preventDefault(); e.stopPropagation(); showConfirmation(project); }} disabled={project.id !== activeProject} sx={{ display: project.id !== activeProject ? "none" : null, padding: "5px" }}>
                        <DeleteIcon />
                    </IconButton>
                </ListItemButton>
            ))}
            <ListItemButton onClick={() => setCreatorOpen(true)}>
                <ListItemIcon><AddIcon /></ListItemIcon>
                <ListItemText primary="Add Project" />
            </ListItemButton>
            <ProjectCreator
                open={creatorOpen}
                onClose={handleCreatorClose}
                addProject={addProject}
                updateProject={updateProject}
                edit={editing}
                project={projects.find(p => p.id === activeProject)}
                index={activeProject}
            />
            <AlertDialog
                open={alertOpen}
                title="Confirm Action"
                message={`Are you sure you want to delete the current Project?`}
                onAgree={handleAgree}
                onDisagree={handleDisagree}
            />
        </React.Fragment>
    );
}

export default Projects;
