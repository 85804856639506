import React from 'react';
import { styled, Toolbar, Divider, List, IconButton, Grid, Typography } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import { MainListItems, SecondaryListItems, BottomListItems } from './listItems';
import { Projects } from './projects/Projects';
import Credit from './Credit';
import { Options } from './Options';


const drawerWidth = '220px';

function Logo() {
  return (
    <Grid container justifyContent={"flex-start"}>
      <Typography variant="logo" style={{ fontSize: 35, fontWeight: "bold", color: "#293133", marginLeft: "10px" }}>
        ᴉi
      </Typography>
    </Grid>
  )
}


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      borderStyle: 'solid',
      borderRadius: '10px',
      borderWidth: '1px',
      borderColor: 'lightgrey',
      boxSizing: 'border-box',
      margin: '5px',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: '60px',
        [theme.breakpoints.up('sm')]: {
          width: '60px',
        },
      }),
    },
  }),
);

export default function CustomDrawer({ open, handleDrawerClose }) {
  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >

        {open && <Logo />}

        {open &&
          <Grid container justifyContent={"flex-end"}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </Grid>
        }

        {!open &&
          <Grid container justifyContent={"center"}>
            <IconButton onClick={handleDrawerClose}>
              <MenuIcon />
            </IconButton>
          </Grid>
        }

      </Toolbar>

      <Divider />

      <List component="nav" style={{ overflow: "hidden" }}>
        <MainListItems />
        <Divider sx={{ my: 1 }} />
        <Projects />
      </List>

      <List component="nav" style={{ marginTop: "auto", overflow: "hidden" }}>
        <BottomListItems />
      </List>

      {/* <Credit /> */}

    </Drawer>
  );
}
