import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import { LineChart, Scatter, Line, XAxis, YAxis, Label, ResponsiveContainer } from 'recharts';
import Title from './Title';

import moment from 'moment';

import { getExampleChartData,  getPieChartExampleData} from './helpers';

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import { Paper, Typography } from '@mui/material';
import { Divider } from '@mui/material';
import { LinearProgress } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import { MyPieChart } from './Visualization';
import { DateRangeContext } from './DateRangeContext';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';



function TopicComments(props) {

  let getCommentColor = (i) => {
    let sentiment = props.sentiments[i];
    if (sentiment === "Positive") {
      return 'rgba(72, 218, 72, 0.4)';
    } else if (sentiment === "Negative") {
      return 'rgba(231, 80, 80, 0.4)';
    } else {
      return 'lightgrey';
    }
  }

  let comments = props.comments.map((comment, i) => (
    <ListItemButton sx={{ width: '95%', backgroundColor: getCommentColor(i) }}>
      {comment}
    </ListItemButton>
  ));

  console.log(comments)

  return (
    <List sx={{ bgcolor: 'background.paper', maxHeight: '500px', overflowY: 'auto', overflowX:'hidden' }}>
      {comments}
    </List>
  );

}

function TopicListItem(props) {

  return (
    <ListItemButton onClick={props.onClick} active={props.active}>
      {/*<ListItemIcon>
        {props.number}
      </ListItemIcon>*/}
      <ListItemText primary={props.name} disableTypography style={{ fontWeight: 'bolder' }}/>
    </ListItemButton>
  )

}

function SubTopicListItem(props) {

  return (
    <ListItemButton onClick={props.onClick} active={props.active}>
      {/*<ListItemIcon>
        {props.number}
      </ListItemIcon>*/}
      <ListItemText primary={props.name} secondary={props.mentions}/>
    </ListItemButton>
  )

}

function TopicDetails(props) {

  const { dateRange } = useContext(DateRangeContext);
  const [pieChartData, setPieChartData] = useState([]);
  const [sentimentData, setSentimentData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const data = await getPieChartExampleData(dateRange, props.topic.name); 
      setPieChartData(data);
      console.log("Topic Data:");
      console.log(data);
    }
    fetchData();
    setSentimentData(getSentimentData())
  }, [props.topic.name, dateRange]);

  let getSentimentData = () => {
    let data = [
      { id: 0, value: props.topic.details.positive, label: 'Positive', color: 'rgba(72, 218, 72, 0.7)' },
      { id: 1, value: props.topic.details.negative, label: 'Negative', color: 'rgba(231, 80, 80, 0.7)' },
      { id: 2, value: props.topic.details.neutral, label: 'Neutral', color: 'lightgrey' }
    ]
    return data;
  }

  let sentimentSum = props.topic.details.positive + props.topic.details.negative + props.topic.details.neutral;
  let sentimentPercentages = [
    props.topic.details.positive/sentimentSum,
    props.topic.details.negative/sentimentSum,
    props.topic.details.neutral/sentimentSum,
  ];

  return (
    <React.Fragment>
      <Grid container spacing={2}>

        <Grid item xs={12}>
          <Grid2 container spacing={3} sx={{  justifyContent: 'center', maxHeight: '250px', margin: "1px", paddingRight:"5px", overflowY: 'auto', overflowX:'hidden'}}>
            
            <Grid item xs={2} p={1} height={"150px"} alignContent={"end"}>
              <Typography sx={{mb: 2}} fontSize={50} fontWeight={'semibold'}>
                {props.topic.details.amount}
              </Typography>
              <Typography fontSize={14} fontWeight={'semibold'}>
                Mentions
              </Typography>
            </Grid>

            <Grid item xs={1} p={1} height={"150px"} alignContent={"end"}>
              <Divider orientation="vertical" />
            </Grid>

            <Grid item xs={2} p={1} height={"150px"} alignContent={"end"}>
              <Typography sx={{mb: 2}} fontSize={50} fontWeight={'semibold'}>
                {Math.round(10 * props.topic.details.nps) / 10}
              </Typography>
              <Typography fontSize={14} fontWeight={'semibold'}>
                NPS
              </Typography>
            </Grid>

            <Grid item xs={1} p={1} height={"150px"} alignContent={"end"}>
              <Divider orientation="vertical" />
            </Grid>

            <Grid item xs={6} p={1} height={"150px"} alignContent={"end"}>
              
              <Grid container marginBottom={"10px"}>

                <Grid item xs={4}>
                  <Typography fontSize={45} fontWeight={'semibold'} letterSpacing={'-2px'} color={'rgba(72, 218, 72, 0.7)'}>
                    {props.topic.details.positive}
                  </Typography>
                </Grid>
                <Grid item xs={4} >
                  <Typography fontSize={45} fontWeight={'semibold'} letterSpacing={'-2px'} color={'rgba(231, 80, 80, 0.7)'}>
                    {props.topic.details.negative}
                  </Typography>
                </Grid>
                <Grid item xs={4} >
                  <Typography fontSize={45} fontWeight={'semibold'} letterSpacing={'-2px'} color={'lightgrey'}>
                    {props.topic.details.neutral}
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography fontSize={10} fontWeight={'bold'} color={'rgba(72, 218, 72, 0.7)'}>
                    Positive
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography fontSize={10} fontWeight={'bold'} color={'rgba(231, 80, 80, 0.7)'}>
                    Negative
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography fontSize={10} fontWeight={'bold'} color={'lightgrey'}>
                    Neutral
                  </Typography>
                </Grid>

              </Grid>
              
              <Typography fontSize={14} fontWeight={'semibold'}>
                Sentiments
              </Typography>

            </Grid>

          </Grid2>
        </Grid>

        <Grid item xs={12}>
          <Divider orientation="horizontal" />
        </Grid>

        <Grid item xs={12}>
          <TopicComments comments={props.topic.comments} sentiments={props.topic.sentiments} />
        </Grid>

      </Grid>
    </React.Fragment>
  )

}

function TopicExplorer(props) {

  //let topics = [];
  let topics = [];
  let subtopics = [];
  let details = [];

  
  const [activeTopic, setActiveTopic] = useState(0);
  const [activeSubTopic, setActiveSubTopic] = useState(0);

  props.topics.forEach((topic, i) => {
      topics.push(<TopicListItem name={topic.name} number={i} onClick={() => {setActiveTopic(i); setActiveSubTopic(0)} } active={activeTopic === i} />)
    }
  )

  props.topics.forEach((topic, i) => {
    subtopics.push([]);
    details.push([]);
    topic.subtopics.forEach((subtopic, j) => {
      subtopics[i].push(<SubTopicListItem name={subtopic.name} mentions={String(subtopic.details.amount) + " Mentions"} number={j} onClick={() => setActiveSubTopic(j)} active={activeSubTopic === j} />)
      details[i].push(<TopicDetails topic={subtopic} number={j} />)
    })
  })

  return (

    <Grid container spacing={2}>

      <Grid item xs={2.5}>
        <List sx={{ 
          width: '100%', 
          bgcolor: 'background.paper', 
          maxHeight: '700px', 
          minHeight: '700px',
          overflowX:'hidden',
          /*":before": {
            content: '""',
            width: "100%",
            height: "40px",
            background: "linear-gradient(black, transparent)",
            position: "sticky",
            top: "0"
          },
          "::after": {
            content: '""',
            width: "100%",
            height: "40px",
            background: "linear-gradient(transparent, black)",
            position: "absolute",
            bottom: "0"
          }*/
          }}>
          {topics}
        </List>
      </Grid>

      <Grid item xs={3.5}>
        <List sx={{ width: '100%', bgcolor: 'background.paper', minHeight: '600px', maxHeight: '700px', overflowY: 'auto', overflowX:'hidden' }}>
          {subtopics[activeTopic]}
        </List>
      </Grid>

      <Grid item xs={6}>
        {details.length ? details[activeTopic][activeSubTopic] : null}
      </Grid>

    </Grid >
  );
}

export default TopicExplorer