import React, { useState, useEffect, useRef } from 'react';
import { Box, IconButton, Container, Typography } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

const logos = [
  '../images/unternehmertum.png',
  '../images/tcw.png',
  '../images/trium.png',
  '../images/tum.svg',
  '../images/vetter_consulting.webp',
  '../images/dhbw.webp',
];

const content = {
  en: {
    title: 'These are our partner institutions and companies',
  },
  de: {
    title: 'Dies sind unsere Partnerinstitutionen und Unternehmen',
  },
};

const Carousel = ({ language = 'en' }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const intervalRef = useRef(null);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % logos.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - 1 < 0 ? logos.length - 1 : prevIndex - 1
    );
  };

  const startAutoScroll = () => {
    intervalRef.current = setInterval(handleNext, 3000); // Auto-scroll every 3 seconds
  };

  const stopAutoScroll = () => {
    clearInterval(intervalRef.current);
  };

  useEffect(() => {
    startAutoScroll();
    return () => stopAutoScroll(); // Clean up on component unmount
  }, []);

  return (
    <Container
      maxWidth="lg"
      sx={{
        marginTop: '40px',
        marginBottom: '40px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        width: '100%',
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{ marginBottom: '20px', textAlign: 'center' }}
      >
        {content[language].title}
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        onMouseEnter={stopAutoScroll}
        onMouseLeave={startAutoScroll}
        sx={{
          flexDirection: { xs: 'row' }, // Keep arrows in row
        }}
      >
        <IconButton
          onClick={handlePrev}
          aria-label="previous"
          sx={{ display: 'flex' }} // Always show arrows
        >
          <ArrowBack />
        </IconButton>
        <Box
          sx={{
            overflow: 'hidden',
            width: { xs: '100%', sm: '80%' }, // Full width on small screens
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              transform: {
                xs: `translateX(-${currentIndex * 100}%)`, // Adjust translation for small screens to show one logo
                sm: `translateX(-${currentIndex * (100 / 3)}%)`, // Show three logos on medium+ screens
              },
              transition: 'transform 0.5s ease-in-out',
              width: {
                xs: `${logos.length * 100}%`, // Full width for all logos on small screens
                sm: `${(logos.length / 3) * 100}%`, // Adjust width for medium+ screens
              },
            }}
          >
            {logos.map((logo, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minWidth: { xs: '100%', sm: '33.33%' }, // One logo at a time on small screens, three on medium+ screens
                  flexShrink: 0,
                  padding: { xs: '10px', sm: '0' },
                }}
              >
                <img
                  src={logo}
                  alt={`logo-${index}`}
                  style={{
                    width: '100%', // Make images responsive
                    height: 'auto',
                    maxWidth: '200px', // Limit max width
                    objectFit: 'contain',
                  }}
                />
              </Box>
            ))}
          </Box>
        </Box>
        <IconButton
          onClick={handleNext}
          aria-label="next"
          sx={{ display: 'flex' }} // Always show arrows
        >
          <ArrowForward />
        </IconButton>
      </Box>
    </Container>
  );
};

export default Carousel;
