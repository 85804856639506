//import * as React from 'react';
import React, { useState, useContext } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InsightsIcon from '@mui/icons-material/Insights';
import ChatIcon from '@mui/icons-material/Chat';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import UploadIcon from '@mui/icons-material/Upload';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext'; 
import { Typography } from '@mui/material';
import Credit from './Credit';

export function MainListItems() {

  const navigate = useNavigate();
  const location = useLocation();

  return (
  <React.Fragment>
    <ListItemButton active={location.pathname === "/dashboard"} onClick={() => navigate("/dashboard")}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
    <ListItemButton active={location.pathname === "/insights"} onClick={() => navigate("/insights")}>
      <ListItemIcon>
        <InsightsIcon />
      </ListItemIcon>
      <ListItemText primary="Insights" />
    </ListItemButton>
    <ListItemButton active={location.pathname === "/reports"} onClick={() => navigate("/reports")}>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Reports" />
    </ListItemButton>
    {/* <ListItemButton active={location.pathname === "/chat"} onClick={() => navigate("/chat")}>
      <ListItemIcon>
        <ChatIcon />
      </ListItemIcon>
      <ListItemText primary="Chat" />
    </ListItemButton>     */}
    <ListItemButton active={location.pathname === "/upload"} onClick={() => navigate("/upload")}>
      <ListItemIcon>
        <UploadIcon />
      </ListItemIcon>
      <ListItemText primary="Upload" />
    </ListItemButton>
  </React.Fragment>
  )
};

// TODO! (integrate with API)
function getSavedReports() {
  return []
}

export function BottomListItems() {

  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useContext(AuthContext);


  const handleLogout = () => {
    logout();
    //navigate("/login"); 
  };

  console.log(location);

  return (
  <React.Fragment>
    <ListItemButton active={location.pathname === "/configuration"} onClick={() => navigate("/configuration")}>
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText primary="Configuration" />
    </ListItemButton>

    <ListItemButton onClick={handleLogout}>
      <ListItemIcon>
        <LogoutIcon style={{ color: "#ed4337" }} />
      </ListItemIcon>
      <ListItemText primary="Logout" />
    </ListItemButton>

  </React.Fragment>
  )
};