import React, { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import Highlights from './components/Highlights';
import Pricing from './components/Pricing';
import Features from './components/Features';
import Footer from './components/Footer';
import ContactForm from './components/ContactForm';
import Carousel from './components/Carousel';

export default function LandingPage() {
  const [mode, setMode] = useState('dark');
  const [language, setLanguage] = useState('en');

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Box
        sx={{
          width: '100%',
          maxWidth: '1200px', // Restrict the maximum width
          margin: '0 auto', // Center content horizontally
          padding: { xs: '16px', md: '24px' }, // Responsive padding
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', // Center child components vertically
          justifyContent: 'center', // Ensure content is vertically centered
        }}
      >
        <AppAppBar
          mode={mode}
          toggleColorMode={toggleColorMode}
          language={language}
          setLanguage={setLanguage}
        />
        <Hero language={language} />
        <Divider sx={{ width: '100%', my: 5 }} />
        <Carousel language={language}/>
        <Divider sx={{ width: '100%', my: 5 }} />
        <Features language={language} />
        <Divider sx={{ width: '100%', my: 5 }} />
        <Highlights language={language} />
        <Divider sx={{ width: '100%', my: 5 }} />
        {/* <Pricing language={language} /> */}
        <ContactForm language={language} />
        <Footer language={language} />
      </Box>
    </React.Fragment>
  );
}
