import React, { useState, useEffect, useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import axios from 'axios';

import { DateRangeContext } from './DateRangeContext'; 


const DownloadCsvButton = () => {
  const { selectedColumns, selectedFilters, dateRange } = useContext(DateRangeContext); // Use context to get the date range, selected columns, and selected filters

  const handleDownload = () => {
    axios.get('/api/dev/upload/downloadCSV', {
      params: {
          begin: dateRange[0].toISOString(),
          end: dateRange[1].toISOString(),
          selectedColumns: selectedColumns,  
          selectedFilters: selectedFilters   
      },
      headers: {
          'Content-Type': 'application/json'
      },
      responseType: 'blob',
    })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const contentDisposition = response.headers['content-disposition'];
      let filename = 'data.csv'; // Default filename
      
      if (contentDisposition) {
        const match = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        if (match != null && match[1]) { 
          filename = match[1].replace(/['"]/g, ''); // Remove any surrounding quotes
        }
      }

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch(error => {
      console.error('Error downloading the CSV file:', error.message);
    });
  };

  return (
    <IconButton onClick={handleDownload} color="primary" aria-label="download csv">
      <DownloadIcon />
    </IconButton>
  );
};

export default DownloadCsvButton;
