import React, { useState } from 'react';
import { Button, TextField, Box, Typography, MenuItem, Container, Alert } from '@mui/material';
import axios from 'axios';

const content = {
  en: {
    formTitle: 'Get Your Free Demo Access!',
    formDescription: 'Interested in experiencing our tool firsthand? Drop us a message to get your exclusive registration code for a free demo. Let\'s innovate together!',
    nameLabel: 'Name',
    emailLabel: 'Email',
    companyLabel: 'Company',
    professionLabel: 'Profession',
    messageLabel: 'Message',
    messagePlaceholder: 'I am interested in exploring your tool and would like to request access to the free demo version. Please send me a registration code to get me started!',
    professionHelper: 'Please select your profession',
    buttonText: 'Send Message',
    successMessage: 'Your submission was successful!',
    errorMessage: 'An error occurred during submission.',
    nameError: 'Please enter your name.',
    emailError: 'Please enter your email.',
    companyError: 'Please enter your company.',
    professionError: 'Please select your profession.',
  },
  de: {
    formTitle: 'Holen Sie sich Ihren kostenlosen Demo-Zugang!',
    formDescription: 'Interessiert daran, unser Tool aus erster Hand zu erleben? Senden Sie uns eine Nachricht, um Ihren exklusiven Registrierungscode für eine kostenlose Demo zu erhalten.',
    nameLabel: 'Name',
    emailLabel: 'E-Mail',
    companyLabel: 'Unternehmen',
    professionLabel: 'Beruf',
    messageLabel: 'Nachricht',
    messagePlaceholder: 'Ich bin daran interessiert, Ihr Tool zu erkunden und möchte Zugang zur kostenlosen Demo-Version beantragen. Bitte senden Sie mir einen Registrierungscode, um loszulegen!',
    professionHelper: 'Bitte wählen Sie Ihren Beruf',
    buttonText: 'Nachricht senden',
    successMessage: 'Ihre Nachricht wurde erfolgreich gesendet!',
    errorMessage: 'Bei der Übermittlung ist ein Fehler aufgetreten.',
    nameError: 'Bitte geben Sie Ihren Namen ein.',
    emailError: 'Bitte geben Sie Ihre E-Mail-Adresse ein.',
    companyError: 'Bitte geben Sie Ihr Unternehmen ein.',
    professionError: 'Bitte wählen Sie Ihren Beruf.',
  },
};

const professions = {
  en: [
    { value: 'Engineer', label: 'Engineer' },
    { value: 'Marketing', label: 'Marketing' },
    { value: 'Student', label: 'Student' },
    { value: 'Software Developer', label: 'Software Developer' },
    { value: 'Product Manager', label: 'Product Manager' },
    { value: 'Graphic Designer', label: 'Graphic Designer' },
    { value: 'Researcher', label: 'Researcher' },
    { value: 'Data Scientist', label: 'Data Scientist' },
    { value: 'Educator', label: 'Educator' },
    { value: 'Healthcare Professional', label: 'Healthcare Professional' },
    { value: 'Entrepreneur', label: 'Entrepreneur' },
    { value: 'Legal Professional', label: 'Legal Professional' },
    { value: 'HR Professional', label: 'HR Professional' },
    { value: 'Sales Professional', label: 'Sales Professional' },
    { value: 'Consultant', label: 'Consultant' },
    { value: 'Freelancer', label: 'Freelancer' },
    { value: 'Other', label: 'Other' },
  ],
  de: [
    { value: 'Engineer', label: 'Ingenieur' },
    { value: 'Marketing', label: 'Marketing' },
    { value: 'Student', label: 'Student' },
    { value: 'Software Developer', label: 'Softwareentwickler' },
    { value: 'Product Manager', label: 'Produktmanager' },
    { value: 'Graphic Designer', label: 'Grafikdesigner' },
    { value: 'Researcher', label: 'Forscher' },
    { value: 'Data Scientist', label: 'Datenwissenschaftler' },
    { value: 'Educator', label: 'Erzieher' },
    { value: 'Healthcare Professional', label: 'Gesundheitsfachkraft' },
    { value: 'Entrepreneur', label: 'Unternehmer' },
    { value: 'Legal Professional', label: 'Rechtsanwalt' },
    { value: 'HR Professional', label: 'HR-Fachkraft' },
    { value: 'Sales Professional', label: 'Vertriebsfachkraft' },
    { value: 'Consultant', label: 'Berater' },
    { value: 'Freelancer', label: 'Freiberufler' },
    { value: 'Other', label: 'Andere' },
  ],
};

const ContactForm = ({ language }) => {
  const {
    formTitle,
    formDescription,
    nameLabel,
    emailLabel,
    companyLabel,
    professionLabel,
    messageLabel,
    messagePlaceholder,
    professionHelper,
    buttonText,
    successMessage: successMsg,
    errorMessage: errorMsg,
    nameError,
    emailError,
    companyError,
    professionError,
  } = content[language];

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: messagePlaceholder,
    profession: '',
    company: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Clear previous messages
    setErrorMessage('');
    setSuccessMessage('');

    // Check for missing fields
    if (!formData.name) {
      setErrorMessage(nameError);
      return;
    }
    if (!formData.email) {
      setErrorMessage(emailError);
      return;
    }
    if (!formData.company) {
      setErrorMessage(companyError);
      return;
    }
    if (!formData.profession) {
      setErrorMessage(professionError);
      return;
    }

    const headers = {
      'Content-Type': 'application/json',
    };

    try {
      const response = await axios.post('/api/dev/contact', formData, { headers });
      if (response.data.success) {
        setSuccessMessage(successMsg);
        setErrorMessage(''); 
      } else {
        setErrorMessage(response.data.error || errorMsg);
        setSuccessMessage('');
      }
    } catch (error) {
      const errorMessage = error.response?.data?.error || errorMsg;
      setErrorMessage(errorMessage);
      setSuccessMessage('');
      console.error("Submission error", error);
    }
  };

  return (
    <Container
      id="contact"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        sx={{
          '& .MuiTextField-root': { m: 1, width: '70%' },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h4" gutterBottom>
          {formTitle}
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ textAlign: 'center' }}>
          {formDescription}
        </Typography>
        {errorMessage && (
          <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
            {errorMessage}
          </Alert>
        )}
        {successMessage && (
          <Alert severity="success" sx={{ width: '100%', mt: 2 }}>
            {successMessage}
          </Alert>
        )}
        <TextField
          required
          id="name"
          name="name"
          label={nameLabel}
          value={formData.name}
          onChange={handleChange}
        />
        <TextField
          required
          id="email"
          name="email"
          label={emailLabel}
          type="email"
          value={formData.email}
          onChange={handleChange}
        />
        <TextField
          required
          id="company"
          name="company"
          label={companyLabel}
          type="company"
          value={formData.company}
          onChange={handleChange}
        />
        <TextField
          select
          label={professionLabel}
          id="profession"
          name="profession"
          value={formData.profession}
          onChange={handleChange}
          helperText={professionHelper}
        >
          {professions[language].map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          required
          id="message"
          name="message"
          label={messageLabel}
          multiline
          rows={6}
          value={formData.message}
          onChange={handleChange}
        />
        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
          {buttonText}
        </Button>
      </Box>
    </Container>
  );
};

export default ContactForm;
