import React from 'react';
import { List, ListItem, ListItemText, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function SharedUserList({ sharedUsers, onUnshare }) {
    return (
        <List>
            {sharedUsers.map((user, index) => (
                <ListItem key={index} secondaryAction={
                    user.isCurrentUser ? (
                        <Typography variant="body2" color="textSecondary">(You)</Typography>
                    ) : (
                        <IconButton edge="end" onClick={() => onUnshare(user.email)}>
                            <DeleteIcon />
                        </IconButton>
                    )
                }>
                    <ListItemText primary={user.displayName} />
                </ListItem>
            ))}
        </List>
    );
}

export default SharedUserList;
