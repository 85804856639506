import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useTheme, Typography } from '@mui/material';
import { XAxis, YAxis, Label, ResponsiveContainer, AreaChart, Area } from 'recharts';
import Title from './Title';
import moment from 'moment';
import axios from 'axios';
import { DateRangeContext } from './DateRangeContext';
import { green } from '@mui/material/colors';

export default function Chart() {
  const theme = useTheme();
  const { dateRange, selectedFilters, selectedColumns } = useContext(DateRangeContext);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const response = await axios.get('/api/dev/customers/bydates', {
          params: {
            begin: dateRange[0].toISOString(),
            end: dateRange[1].toISOString(),
            selectedFilters: selectedFilters,
            selectedColumns: selectedColumns,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const resp_data = response.data;
        console.log('Last NPS Scores');
        console.log(resp_data);

        // Initialize counters for autoregressive NPS calculation
        let cumulativePromoters = 0;
        let cumulativeDetractors = 0;
        let cumulativePassives = 0;
        let autoregressiveData = [];

        // Iterate over each data point and aggregate scores
        for (let i = resp_data.length - 1; i >= 0; i--) {
          const scores = resp_data[i].NPS; // Array of NPS scores
          const date = resp_data[i].Date;

          // Classify each score in the current date's array
          scores.forEach((score) => {
            if (score >= 9) {
              cumulativePromoters++;
            } else if (score >= 7 && score <= 8) {
              cumulativePassives++;
            } else if (score <= 6) {
              cumulativeDetractors++;
            }
          });

          // Total responses up to the current point
          const totalResponses = cumulativePromoters + cumulativeDetractors + cumulativePassives;

          // Calculate the NPS score up to the current date
          const currentAutoregressiveNPS = ((cumulativePromoters - cumulativeDetractors) / totalResponses) * 100;

          // Convert date to Unix timestamp for x-axis
          const x_data = Math.round(new Date(date).getTime() / 1000);

          // Store the result in an array
          autoregressiveData.push({
            x_data,
            y_data: parseFloat(currentAutoregressiveNPS.toFixed(2)), // Convert string to float for recharts
          });
        }

        // Sort data by date
        autoregressiveData = autoregressiveData.sort((a, b) => a.x_data - b.x_data);
        setChartData(autoregressiveData);
        console.log('Updated chart with autoregressive NPS');
        console.log(autoregressiveData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchChartData();
  }, [dateRange, selectedFilters, selectedColumns]);

  
  if (chartData.length === 0) {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Typography variant="h6" align="center" style={{ color: theme.palette.text.secondary }}>
                No data available
            </Typography>
        </div>
    );
}

  return (
    <React.Fragment>
      <Title>NPS Score Trend</Title>
      <ResponsiveContainer>
        {chartData.length > 0 ? (
          <AreaChart
            data={chartData}
            margin={{
              top: 8,
              right: 50,
              bottom: 25,
              left: 5,
            }}
          >
            <XAxis
              dataKey="x_data"
              domain={[chartData[0], chartData[-1]]}
              stroke={theme.palette.text.secondary}
              style={theme.typography.body2}
              tickFormatter={(unixTime) => moment(unixTime * 1000).format('YYYY-MM-DD')}
              type="number"
            />
            <YAxis
              stroke={theme.palette.text.secondary}
              style={theme.typography.body2}
              domain={[-100, 100]}
              yAxisId="1" // Matching yAxisId for both the Area and YAxis
            >
              <Label
                dx={20}
                angle={270}
                position="left"
                style={{
                  textAnchor: 'middle',
                  fill: theme.palette.text.secondary,
                  ...theme.typography.body1,
                }}
              >
                NPS
              </Label>
            </YAxis>
            <defs>
              <linearGradient id="colorAbove" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="rgba(231, 80, 80, 0.6)" stopOpacity={0.8} />
                <stop offset="100%" stopColor="rgba(231, 80, 80, 0.6)" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorBelow" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="rgba(72, 218, 72, 0.6)" stopOpacity={0.8} />
                <stop offset="100%" stopColor="rgba(72, 218, 72, 0.6)" stopOpacity={0} />
              </linearGradient>
            </defs>
            <Area
              type="monotone"
              dataKey="y_data"
              stroke={theme.palette.primary.main}
              fill="url(#colorBelow)"
              fillOpacity={1}
              yAxisId="1"
              baseValue={-100} // Fill below the curve
            />
            <Area
              type="monotone"
              dataKey="y_data"
              stroke={green}
              fill="url(#colorAbove)"
              fillOpacity={1}
              yAxisId="1"
              baseValue={100} // Fill above the curve
            />
          </AreaChart>
        ) : (
          <Typography
            variant="h6"
            align="center"
            style={{ marginTop: '120px', color: theme.palette.text.secondary }}
          >
            No data available
          </Typography>
        )}
      </ResponsiveContainer>
    </React.Fragment>
  );
}
