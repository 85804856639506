import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { Container, Grid, Paper, Avatar, Typography, CircularProgress, List, ListItem } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme();



const Profile = () => {
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get('/api/profile', {
    })
    .then(response => {
      setProfileData(response.data.data);
      setLoading(false);
    })
    .catch(error => {
      console.error('There was an error fetching the profile data!', error);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth="md">
          <CircularProgress />
        </Container>
      </ThemeProvider>
    );
  }

  if (!profileData) {
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth="md">
          <Typography variant="h6">Error loading profile data.</Typography>
        </Container>
      </ThemeProvider>
    );
  }

  return (
<ThemeProvider theme={theme}>
    <Container maxwidth ="xs">
      <Paper>
        <Grid container sx={{ marginTop: 5, marginBottom: 5 }} spacing={3} direction="row" alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={6} sx={{ padding: 5, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Avatar sx={{ width: 150, height: 150 }} alt={profileData.username} src={profileData.avatarUrl} />
            <Typography variant="h5" component="h2" gutterBottom>
              <b>{profileData.fullName}</b>
            </Typography>
            <Typography variant="body1" gutterBottom>
              Email: <b>{profileData.email}</b>
            </Typography>
            <Typography variant="body1" gutterBottom>
              Account Created: <b>{new Date(profileData.registration_date).toLocaleDateString()}</b>
            </Typography>
            <Typography variant="body1" gutterBottom>
              Account Status: <b>Demo Access</b>
            </Typography>
            <Typography variant="body1" gutterBottom>
              Credits: <b>{profileData.credit_score}</b>
            </Typography>
          </Grid>
          {/* <Grid item xs={12} sm={6} sx={{ padding: 5, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h6"><b>Available Datasets:</b></Typography>
            <List>
              {profileData.data_sources.map((source, index) => (
                <ListItem key={index}>
                  <Typography variant="body1">{source}</Typography>
                </ListItem>
              ))}
            </List>
          </Grid> */}
        </Grid>
      </Paper>
    </Container>
  </ThemeProvider>

  
  );
};

export default Profile;
