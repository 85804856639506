import React, { useState, useEffect, useContext } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Label, LabelList } from 'recharts';
import axios from 'axios';
import Title from './Title';
import { Typography, useTheme } from '@mui/material';
import { DateRangeContext } from './DateRangeContext';

// Custom Label Component to handle wrapping of text and positioning at the end of the bars
const CustomLabel = ({ x, y, width, height, value }) => {
    const lineBreakLimit = 20; // Adjust this value to control the maximum characters per line
    const words = value.split(' ');
    const lines = [];
    let currentLine = '';

    // Split text into multiple lines based on the line break limit
    words.forEach((word) => {
        if ((currentLine + word).length > lineBreakLimit) {
            lines.push(currentLine.trim());
            currentLine = word + ' ';
        } else {
            currentLine += word + ' ';
        }
    });

    lines.push(currentLine.trim());

    // Calculate y position dynamically to align text properly at the tip of the bar
    const textY = y + height / 2 - (lines.length * 1); // Adjusting position based on number of lines

    return (
        <text x={x + width + 2} y={textY} fill="#181818" textAnchor="start">
            {lines.map((line, index) => (
                <tspan key={index} x={x + width + 2} dy={index * 14}>{/* Adjust dy for line height */}
                    {line}
                </tspan>
            ))}
        </text>
    );
};

export function MyBarChart(props) {
    const theme = useTheme();
    const [data, setData] = useState([]);
    const { selectedColumns, selectedFilters, dateRange } = useContext(DateRangeContext);

    useEffect(() => {
        axios.get('/api/dev/data/barchart', {
            params: {
                begin: dateRange[0].toISOString(),
                end: dateRange[1].toISOString(),
                selectedColumns: selectedColumns,
                selectedFilters: selectedFilters
            },
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            const responseData = response.data;
            console.log("BARCHART");
            console.log(responseData);

            const transformedData = responseData.map(item => {
                const sentiments = item.Sentiments.reduce((acc, sentiment) => {
                    acc[sentiment.Sentiment] = sentiment.Count;
                    return acc;
                }, { Positive: 0, Neutral: 0, Negative: 0 });

                const total = sentiments.Positive + sentiments.Neutral + sentiments.Negative;

                return {
                    Subject: item.Subject,
                    ...sentiments,
                    total
                };
            });

            const top5Data = transformedData.sort((a, b) => b.total - a.total).slice(0, 5);

            setData(top5Data);
        })
        .catch(error => {
            console.error(error);
            setData([]);
        });
    }, [dateRange, selectedColumns, selectedFilters]);

    if (data.length === 0) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Typography variant="h6" align="center" style={{ color: theme.palette.text.secondary }}>
                    No data available
                </Typography>
            </div>
        );
    }

    const barColors = {
        'Positive': 'rgba(72, 218, 72, 0.6)',
        'Neutral': 'lightgrey',
        'Negative': 'rgba(231, 80, 80, 0.6)'
    };

    return (
        <React.Fragment>
            <Title>Top 5 Topics</Title>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                        data={data}
                        layout="vertical"
                        margin={{
                            top: 15, right: 120, left: 0, bottom: 10,
                        }}
                    >
                        <XAxis type="number">
                            <Label value="Frequency" position="insideBottom" offset={-5} />
                        </XAxis>
                        <YAxis 
                            type="category" 
                            dataKey="Subject" 
                            tick={false} 
                        >
                            <Label 
                                value="Topics" 
                                position="insideRight"
                                dy={-10} 
                                angle={-90} 
                                offset={30} 
                            />
                        </YAxis>
                        <Tooltip />
                        {Object.keys(barColors).map((sentiment, index) => (
                            <Bar key={sentiment} dataKey={sentiment} stackId="a" fill={barColors[sentiment]}>
                                {index === Object.keys(barColors).length - 1 && (
                                    <LabelList dataKey="Subject" position="right" content={<CustomLabel />} />
                                )}
                            </Bar>
                        ))}
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </React.Fragment>
    );
}
