import React, { useEffect, useState } from 'react';
import {
    Box, Button, Dialog, DialogActions, FormControl, Grid, IconButton,
    Paper, TextField, Typography, Divider, Dialog as ConfirmDialog, DialogTitle, DialogContent
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SeedTopic from './SeedTopic';
import SharedUserList from './SharedUserList';
import axios from 'axios';

function ProjectCreator({ open, onClose, addProject, updateProject, edit, project, index }) {
    const [projectTitle, setProjectTitle] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [seedTopics, setSeedTopics] = useState([]);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [sharedUsers, setSharedUsers] = useState([]);
    const [currentUserEmail, setCurrentUserEmail] = useState('');
    const [confirmShareOpen, setConfirmShareOpen] = useState(false);
    const [confirmUnshareOpen, setConfirmUnshareOpen] = useState(false);
    const [userToUnshare, setUserToUnshare] = useState(null);

    useEffect(() => {
        if (edit && project) {
            setProjectTitle(project.title);
            setProjectDescription(project.description);
            setSeedTopics(project.topics);
            // Fetch current user email and shared users sequentially
            fetchCurrentUserEmailAndSharedUsers();
        } else {
            setProjectTitle('');
            setProjectDescription('');
            setSeedTopics([]);
            setSharedUsers([]);
            setCurrentUserEmail('');
        }
    }, [edit, project]);

    const fetchCurrentUserEmailAndSharedUsers = async () => {
        try {
            const email = await fetchCurrentUserEmail();
            await fetchSharedUsers(email);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchCurrentUserEmail = async () => {
        try {
            const response = await axios.get('/api/dev/current_user_email', {
                withCredentials: true,
            });
            setCurrentUserEmail(response.data.email);
            return response.data.email; // Return the email for further use
        } catch (error) {
            console.error('Error fetching current user email:', error);
            return null;
        }
    };

    const fetchSharedUsers = async (email) => {
        if (!email) return; // Ensure email is available
        try {
            const response = await axios.get('/api/dev/project_shared_with', {
                params: { projectId: project.id },
                withCredentials: true,
            });
            // Process shared users to add displayName and isCurrentUser
            const processedUsers = response.data.map(user => ({
                ...user,
                isCurrentUser: user.email === email,
                displayName: user.email === email ? `${user.email}` : user.email,
            }));
            // Sort to place current user first
            processedUsers.sort((a, b) => b.isCurrentUser - a.isCurrentUser);
            setSharedUsers(processedUsers);
        } catch (error) {
            console.error('Error fetching shared users:', error);
        }
    };

    const addSeedTopic = () => setSeedTopics([...seedTopics, { title: '', description: '' }]);
    const removeSeedTopic = (i) => setSeedTopics(seedTopics.filter((_, index) => index !== i));
    const setTopicTitle = (i, title) => {
        setSeedTopics(seedTopics.map((topic, index) => index === i ? { ...topic, title } : topic));
    };
    const setTopicDescription = (i, description) => {
        setSeedTopics(seedTopics.map((topic, index) => index === i ? { ...topic, description } : topic));
    };

    const createProjectAndClose = () => {
        const newProject = {
            id: index,
            title: projectTitle,
            description: projectDescription,
            topics: seedTopics,
            owner_id: project ? project.owner_id : null, // Maintain owner_id if editing
        };
        if (edit) {
            updateProject(index, newProject);
        } else {
            addProject(newProject);
        }
        onClose();
    };

    const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);

    const handleShareConfirmation = () => {
        if (!validateEmail(email)) {
            setEmailError('Invalid email address');
            return;
        }
        setEmailError('');
        setConfirmShareOpen(true);
    };

    const handleShare = async () => {
        setConfirmShareOpen(false);
        if (!project || !project.id) {
            setEmailError('Project not created yet. Please save the project first.');
            return;
        }
        try {
            await axios.post('/api/dev/project_share', {
                projectId: project.id,
                email,
            }, { withCredentials: true });
            setEmail('');
            fetchSharedUsers(currentUserEmail);
        } catch (error) {
            console.error('Error sharing project:', error);
            if (error.response && error.response.status === 404) {
                setEmailError('User with this email does not exist');
            } else {
                setEmailError('An error occurred while sharing the project');
            }
        }
    };

    const handleUnshareConfirmation = (userEmail) => {
        if (userEmail === currentUserEmail) {
            console.error("Cannot unshare yourself from the project");
            return;
        }
        setUserToUnshare(userEmail);
        setConfirmUnshareOpen(true);
    };

    const handleUnshare = async () => {
        setConfirmUnshareOpen(false);
        if (!userToUnshare) return;
        try {
            await axios.post('/api/dev/project_unshare', {
                projectId: project.id,
                email: userToUnshare,
            }, { withCredentials: true });
            fetchSharedUsers(currentUserEmail);
        } catch (error) {
            console.error('Error unsharing project:', error);
        }
        setUserToUnshare(null);
    };

    return (
        <>
            <Dialog onClose={onClose} open={open} fullWidth maxWidth="xl">
                <Grid container p={2} spacing={2}>
                    {/* Project Title */}
                    <Grid item xs={12}>
                        <FormControl sx={{ width: "100%" }}>
                            <TextField
                                id="title"
                                variant="standard"
                                placeholder="Project Title"
                                value={projectTitle}
                                inputProps={{ style: { fontSize: 30 } }}
                                onChange={(e) => setProjectTitle(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    {/* Main Content */}
                    <Grid item container xs={12} spacing={2}>
                        {/* Left Side */}
                        <Grid item xs={12} md={8}>
                            {/* Project Description */}
                            <Paper sx={{ padding: 2, mb: 2, elevation: 3 }}>
                                <Typography variant="subtitle1" fontWeight="bold" mb={1}>Project Description</Typography>
                                <FormControl sx={{ width: "100%" }}>
                                    <TextField
                                        id="description"
                                        placeholder="Project Description"
                                        value={projectDescription}
                                        variant="outlined"
                                        multiline
                                        onChange={(e) => setProjectDescription(e.target.value)}
                                    />
                                </FormControl>
                            </Paper>
                            {/* Seed Topics */}
                            <Paper sx={{ padding: 2, mb: 2, elevation: 3 }}>
                                <Typography variant="subtitle1" fontWeight="bold" mb={1}>Seed Topics</Typography>
                                <Box sx={{ maxHeight: 500, overflowY: 'auto', pr: 1 }}>
                                    {seedTopics.map((topic, i) => (
                                        <Paper key={i} sx={{ mt: 2, p: 2, elevation: 5, backgroundColor: '#FAF9F6' }}>
                                            <SeedTopic
                                                index={i}
                                                title={topic.title}
                                                description={topic.description}
                                                setTopicTitle={setTopicTitle}
                                                setTopicDescription={setTopicDescription}
                                            />
                                            <IconButton onClick={() => removeSeedTopic(i)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Paper>
                                    ))}
                                </Box>
                                <Button variant='outlined' onClick={addSeedTopic} sx={{ width: "100%", mt: 2 }} color="secondary">
                                    <AddIcon /> Add Seed Topic
                                </Button>
                            </Paper>
                        </Grid>
                        {/* Divider */}
                        <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
                        {/* Right Side - Project Settings */}
                        <Grid item xs={12} md={3} sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography variant="h6" fontWeight="bold" mb={2}>Project Settings</Typography>
                                {/* Share Project */}
                                <Typography variant="subtitle1" fontWeight="bold" mb={1}>Share Project with:</Typography>
                                <FormControl sx={{ width: "100%", mb: 2 }}>
                                    <TextField
                                        id="email"
                                        placeholder="Email Address"
                                        value={email}
                                        error={!!emailError}
                                        helperText={emailError}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </FormControl>
                                <Button variant="contained" color="primary" onClick={handleShareConfirmation} sx={{ mb: 2 }}>
                                    Share
                                </Button>
                                {/* Project Shared With */}
                                {sharedUsers.length > 0 ? (
                                    <SharedUserList sharedUsers={sharedUsers} onUnshare={handleUnshareConfirmation} />
                                ) : (
                                    <Typography variant="body2" color="textSecondary">
                                        Not shared with anyone yet.
                                    </Typography>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                    {/* Dialog Actions */}
                    <Grid item xs={12}>
                        <DialogActions sx={{ width: "100%" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Button onClick={onClose} variant="outlined" color="primary" sx={{ width: "100%" }}>
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button onClick={createProjectAndClose} variant="contained" color="primary" sx={{ width: "100%" }}>
                                        {edit ? "Update" : "Create"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </Grid>
                </Grid>
            </Dialog>

            {/* Confirmation Dialog for Sharing */}
            <ConfirmDialog open={confirmShareOpen} onClose={() => setConfirmShareOpen(false)}>
                <DialogTitle>Confirm Share</DialogTitle>
                <DialogContent>
                    Are you sure you want to share the project with "{email}"?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmShareOpen(false)}>Cancel</Button>
                    <Button onClick={handleShare} variant="contained" color="primary">Confirm</Button>
                </DialogActions>
            </ConfirmDialog>

            {/* Confirmation Dialog for Unsharing */}
            <ConfirmDialog open={confirmUnshareOpen} onClose={() => setConfirmUnshareOpen(false)}>
                <DialogTitle>Confirm Unshare</DialogTitle>
                <DialogContent>
                    Are you sure you want to remove "{userToUnshare}" from the project?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmUnshareOpen(false)}>Cancel</Button>
                    <Button onClick={handleUnshare} variant="contained" color="primary">Confirm</Button>
                </DialogActions>
            </ConfirmDialog>
        </>
    );
}

export default ProjectCreator;
