import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';

const logoStyle = {
  maxHeight: '80px',
  cursor: 'pointer',
  p: 0,
  m: 0,
};

function AppAppBar({ mode, toggleColorMode, language, setLanguage }) {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      setTimeout(() => {
        window.scrollTo({ top: targetScroll, behavior: 'smooth' });
      }, 2);
      setOpen(false);
    }
  };

  const handleLanguageChange = (event, newLanguage) => {
    if (newLanguage !== null) {
      setLanguage(newLanguage);
    }
  };

  const content = {
    en: { signIn: 'Sign in', signUp: 'Sign up' },
    de: { signIn: 'Anmelden', signUp: 'Registrieren' },
  };

  return (
    <AppBar position="relative" sx={{ backgroundColor: 'transparent', boxShadow: 'none', padding: '10px 0' }}>
      <Container maxWidth="lg">
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 0 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
            <a href="/">
              <img src="../images/wayu_trans.png" style={logoStyle} alt="logo" />
            </a>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
            <ToggleButtonGroup value={language} exclusive onChange={handleLanguageChange} size="small">
              <ToggleButton value="de">
                <img src="../images/flag_de.png" alt="German flag" style={{ width: 15, height: 15 }} />
              </ToggleButton>
              <ToggleButton value="en">
                <img src="../images/flag_en.png" alt="English flag" style={{ width: 15, height: 15 }} />
              </ToggleButton>
            </ToggleButtonGroup>
            <Button variant="text" size="medium" href="/login" sx={{ marginLeft: 2, fontSize: '1rem' }}>
              {content[language].signIn}
            </Button>
            <Button variant="contained" size="medium" href="/register" sx={{ marginLeft: 1, fontSize: '1rem' }}>
              {content[language].signUp}
            </Button>
          </Box>
          <IconButton sx={{ display: { md: 'none' } }} onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </Container>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
      <Box sx={{ width: 250, padding: 5, marginTop: 2 }}> {/* Added margin from the top */}
        <Button fullWidth variant="contained" href="/register" sx={{ marginTop: 2, fontSize: '1rem' }}>
          {content[language].signUp}
        </Button>
        <Button fullWidth variant="text" href="/login" sx={{ marginTop: 1, fontSize: '1rem' }}>
          {content[language].signIn}
        </Button>
        <ToggleButtonGroup
          sx={{
            display: 'flex',           // Flex display to align items properly
            justifyContent: 'center',  // Center horizontally
            marginTop: 3,              // Additional margin from the top
          }}
          value={language}
          exclusive
          onChange={handleLanguageChange}
          size="small"
        >
          <ToggleButton value="de">
            <img src="../images/flag_de.png" alt="German flag" style={{ width: 15, height: 15 }} />
          </ToggleButton>
          <ToggleButton value="en">
            <img src="../images/flag_en.png" alt="English flag" style={{ width: 15, height: 15 }} />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      </Drawer>
    </AppBar>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
  language: PropTypes.oneOf(['en', 'de']).isRequired,
  setLanguage: PropTypes.func.isRequired,
};

export default AppAppBar;
