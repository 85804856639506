import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import LoadingSpinner from './helpers/LoadingSpinner';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log("Authentication");
        axios.get('/api/profile', { withCredentials: true })
            .then(response => {
                if (response.data.success) {
                    setIsLoggedIn(true);
                }
            })
            .catch(error => {
                console.error('Profile fetch error', error);
                setIsLoggedIn(false);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const login = () => {
        setIsLoggedIn(true);
    };

    const logout = () => {
        axios.post('/api/logout', {}, { withCredentials: true })
            .then(response => {
                if (response.data.success) {
                    setIsLoggedIn(false);
                    
                }
            })
            .catch(error => {
                console.error('Logout error', error);
            });
    };

    return (
        <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
            {loading ? <LoadingSpinner /> : children}
        </AuthContext.Provider>
    );
};
