import React, { useEffect, useState, useContext } from 'react';
import {CircularProgress, Box, Paper, Typography} from '@mui/material';
import { SubmitContext } from './SubmitContext';

const Credit = () => {
  const [creditInfo, setCreditInfo] = useState({ credit_score: 0, max_credit_score: 0 });
  const [loading, setLoading] = useState(true);
  const { isSubmitted } = useContext(SubmitContext);

  useEffect(() => {
    const fetchCreditInfo = async () => {
      try {
        // Replace this URL with the actual endpoint from your backend
        const response = await fetch('/api/dev/credit',{
            method: 'GET', 
            headers: {
                'Content-Type': 'application/json'
            }
    
        });
        const data = await response.json();
        console.log("Credit");
        console.log(data);
        setCreditInfo({ credit_score: data.credit_score, max_credit_score: data.max_credit_score });
      } catch (error) {
        console.error("Failed to fetch credit info:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCreditInfo();

  }, [isSubmitted]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center"       
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: 1, // padding
      }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
<Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        justifyContent: 'left',
        p: 1, // padding
        backgroundColor: 'lightgrey',
        opacity: "70%",
        position: 'fixed',
        top: '20px',
        right: '20px',
        borderRadius: '20px'
      }}
    >
        <Typography variant="inherit" component="h5" fontWeight={"bold"} fontSize={14}>
          {`${creditInfo.credit_score} ₹ / ${creditInfo.max_credit_score} ₹`}
        </Typography>
    </Box>
  );
};

export default Credit;
