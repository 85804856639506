import React, { useState, useContext, useEffect } from 'react';
import { DateRangeContext } from './DateRangeContext';
import Button from '@mui/material/Button';
import { Grid, Paper, Typography, Alert, Box, Divider, Card, CardContent, List, ListItem, ListItemText, IconButton, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Radio } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { SubmitContext } from './SubmitContext';
import Title from './Title';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';

const getColor = (type, weight, frequency) => {
  const weightIntensity = {
    'Very High': 0.9,
    'High': 0.7,
    'Moderate': 0.5,
    'Low': 0.3,
  };

  const frequencyIntensity = {
    'Very Common': 0.9,
    'Common': 0.7,
    'Moderate': 0.5,
    'Rare': 0.3,
  };

  const intensity = Math.max(weightIntensity[weight], frequencyIntensity[frequency]);

  if (type === 'positive') {
    return `rgba(72, 218, 72, ${intensity})`; 
  } else {
    return `rgba(231, 80, 80, ${intensity})`; 
  }
};

function Section({ title, data, type }) {
  return (
    <Box my={4}>
      <Typography variant="h5" gutterBottom>{title}</Typography>
      <Grid container spacing={2}>
        {data.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ backgroundColor: getColor(type, item.Weight, item.Frequency), height: '100%' }}>
              <CardContent>
                <Typography variant="h6">{item.Theme}</Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>{item.Description}</Typography>
                <Divider />
                <Typography variant="body2"><strong>Weight:</strong> {item.Weight}</Typography>
                <Typography variant="body2"><strong>Frequency:</strong> {item.Frequency}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

function KeyTakeawaysSection({ title, data }) {
  return (
    <Box my={4}>
      <Typography variant="h5" gutterBottom>{title}</Typography>
      {data.map((item, index) => (
        <Box key={index} my={2} p={2} sx={{ backgroundColor: '#f5f5f5', borderRadius: '5px' }}>
          <Typography variant="h6">Takeaway {index + 1}</Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>{item.Takeaway}</Typography>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6">Recommendation</Typography>
          <Typography variant="body2">{item.Recommendation}</Typography>
        </Box>
      ))}
    </Box>
  );
}

function Summary() {
  const [data, setData] = useState();
  const [fetching, setFetching] = useState(false);
  const { dateRange, selectedFilters, selectedColumns } = useContext(DateRangeContext);
  const { isSubmitted, triggerSubmit } = useContext(SubmitContext);
  const [errorMessage, setErrorMessage] = useState('');
  const [cost, setCost] = useState(null);
  const [reports, setReports] = useState([]);
  const [activeReport, setActiveReport] = useState('');
  const [editingReport, setEditingReport] = useState(null);
  const [newReportName, setNewReportName] = useState('');
  const [deleteConfirm, setDeleteConfirm] = useState(null);

  useEffect(() => {
    const fetchCostData = () => {
      axios.get('/api/dev/insights/get_cost', {
        params: {
          begin: dateRange[0].toISOString(),
          end: dateRange[1].toISOString(),
          selectedFilters: selectedFilters,
          selectedColumns: selectedColumns,
        },
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        setCost(response.data.cost);
        setErrorMessage('');
      })
      .catch(err => {
        setErrorMessage(err.message);
        setCost(null);
      });
    };

    fetchCostData();
  }, [dateRange, selectedFilters, selectedColumns ]);

  const fetchData = () => {
    setErrorMessage('');
    setFetching(true);
    axios.get('/api/dev/insights/get_summary', {
      params: {
        begin: dateRange[0].toISOString(),
        end: dateRange[1].toISOString(),
        selectedFilters: selectedFilters,
        selectedColumns: selectedColumns
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      const data = response.data;
      console.log("DEBUG");
      console.log(data);
      return data;
    })
    .then((res) => {
      if (res.error) {
        setErrorMessage(`Error: ${res.error || "An error occurred during Summary Generation."}`);
        setFetching(false);
        setData(null);
        return;
      }
      setData(res.predicted_summary);
      triggerSubmit();
      fetchReports(); // Fetch reports after generating a new one
      setFetching(false); // Stop the loading indicator
    })
    .catch((error) => {
      if (error.response) {
        setErrorMessage(`Error: ${error.response?.data?.error || "An error occurred during Summary Generation."}`);
      } else {
        setErrorMessage('An unexpected error occurred.');
      }
      setFetching(false); // Stop the loading indicator
    });
  };

  const fetchReports = () => {
    axios.get('/api/dev/insights/get_reports')
      .then(response => {
        setReports(response.data.reports);
        setActiveReport(response.data.active_report);
        // Automatically activate the latest report
        if (response.data.reports.length > 0) {
          const latestReport = response.data.reports[0];
          setData(latestReport.report_data);
          // handleActivate(latestReport.report_id, true);
        }
      })
      .catch(err => {
        console.error('Error fetching reports:', err);
      });
  };

  useEffect(() => {
    fetchReports(); // Fetch reports on component mount
  }, []);

  const handleActivate = (reportId, automatic = false) => {
    axios.post('/api/dev/insights/activate_report', { report_id: reportId })
      .then(() => {
        setActiveReport(reportId);
        if (!automatic) {
          setData(reports.find(report => report.report_id === reportId).report_data);
        }
      })
      .catch(err => {
        console.error('Error activating report:', err);
      });
  };

  const handleDelete = (reportId) => {
    axios.post('/api/dev/insights/delete_report', { report_id: reportId })
      .then(() => {
        fetchReports();
        setDeleteConfirm(null);
      })
      .catch(err => {
        console.error('Error deleting report:', err);
      });
  };

  const handleRename = (reportId, newName) => {
    axios.post('/api/dev/insights/rename_report', { report_id: reportId, new_name: newName })
      .then(() => {
        fetchReports();
        setEditingReport(null);
        setNewReportName('');
      })
      .catch(err => {
        console.error('Error renaming report:', err);
      });
  };

  const isDataAvailable = key => !fetching && data && data[key];

  return (
    <Box>
      <Paper sx={{ p: 4, mb: 4 }}>
        <Grid container spacing={2}>
          {errorMessage && (
            <Alert severity={"error"} sx={{ width: '100%', mt: 2 }}>
              {errorMessage}
            </Alert>
          )}

          <Grid item xs={12} lg={12}>
            {fetching && <LinearProgress style={{ borderRadius: "5px" }} />}

            {isDataAvailable("PositiveFeedback") &&
              <Section title="Positive Feedback Themes" data={data["PositiveFeedback"]} type="positive" />
            }
            {isDataAvailable("NegativeFeedback") &&
              <Section title="Negative Feedback Themes" data={data["NegativeFeedback"]} type="negative" />
            }
            {isDataAvailable("KeyTakeawaysAndRecommendations") &&
              <KeyTakeawaysSection title="Key Takeaways and Recommendations" data={data["KeyTakeawaysAndRecommendations"]} />
            }
          </Grid>

          <Grid item xs={12} lg={12} sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={fetchData}
                  disabled={fetching}
                >
                  Generate Feedback Report
                </Button>
                <Typography sx={{ ml: 2 }}>
                  Cost: {cost} ₹
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={{ p: 4 }}>
        <Title>Generated Reports</Title>
        {reports.length === 0 ? (
          <Typography variant="body1" sx={{ mt: 2 }}>
            No generated reports yet.
          </Typography>
        ) : (
          <List>
            {reports.map((report, index) => (
              <ListItem key={index} sx={{ backgroundColor: report.report_id === activeReport ? '#e0e0e0' : 'inherit' }}>
                <Radio
                  checked={report.report_id === activeReport}
                  onChange={() => handleActivate(report.report_id)}
                  value={report.report_id}
                  name="active-report"
                  inputProps={{ 'aria-label': report.report_name }}
                />
                <ListItemText
                  primary={editingReport === report.report_id ? (
                    <TextField
                      value={newReportName}
                      onChange={(e) => setNewReportName(e.target.value)}
                      placeholder="Edit report name"
                    />
                  ) : (
                    report.report_name
                  )}
                  secondary={`Generated on: ${new Date(report.created_at).toLocaleString()}`}
                />
                {editingReport === report.report_id ? (
                  <IconButton edge="end" onClick={() => handleRename(report.report_id, newReportName)} title="Save">
                    <CheckIcon />
                  </IconButton>
                ) : (
                  <IconButton edge="end" onClick={() => setEditingReport(report.report_id)} title="Edit">
                    <EditIcon />
                  </IconButton>
                )}
                <IconButton edge="end" onClick={() => setDeleteConfirm(report.report_id)} title="Delete">
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        )}
      </Paper>


      <Dialog
        open={Boolean(deleteConfirm)}
        onClose={() => setDeleteConfirm(null)}
      >
        <DialogTitle>Delete Report</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this report?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirm(null)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleDelete(deleteConfirm)} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Summary;
