import axios from 'axios';

function sortByKey(data, key = "x_data") {
  let keys = [];
  let indices = [];

  for (let i = 0; i < data.length; i++) {
    keys.push(data[i][key])
    indices.push(i)
  }

  indices.sort((a, b) => keys[a] - keys[b])

  let sorted = indices.map(i => data[i]);

  return sorted;
}

export function getExampleInsights(ids = [1, 2, 3, 4, 5]) {
  return axios.get('/api/dev/insights/engine', {
    params: { IDs: ids }
  })
  .then(response => {
    console.log(response);
    return response.data;
  })
  .catch(error => {
    console.error(error);
    
  });
}

export function getExampleChartData(n_comments = 50, x = "Date", y = "NPS") {
  return axios.get('/api/dev/customers', {
    params: { last_n: n_comments }
  })
  .then(response => {
    console.log(response);

    const example_data = response.data;
    console.log(example_data);

    let data = [];

    for (let i = 0; i < example_data.length; i++) {
      let x_data = example_data[i][x];
      x_data = Math.round(new Date(x_data).getTime() / 1000);

      let y_data = example_data[i][y];
      y_data = Number(y_data);

      data.push({ x_data, y_data });
    }

    data = sortByKey(data, "x_data");

    return data;
  })
  .catch(error => {
    console.error(error);
    
  });
}

export function getChartDataByDates(startDate, endDate, x = "Date", y = "NPS") {
  return axios.get('/api/dev/customers/bydates', {
    params: {
      begin: startDate.toISOString(),
      end: endDate.toISOString()
    },
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(response => {
    const resp_data = response.data;

    let data = [];

    for (let i = 0; i < resp_data.length; i++) {
      let x_data = resp_data[i][x];
      x_data = Math.round(new Date(x_data).getTime() / 1000);

      let y_data = resp_data[i][y];
      y_data = Number(y_data);

      data.push({ x_data, y_data });
    }

    data = sortByKey(data, "x_data");

    return data;
  })
  .catch(error => {
    console.error(error);
    
  });
}

// Generate Order Data
function createData(id, date, country, comment, nps) {
  return { id, date, country, comment, nps };
}

export function getExampleData(last_n = 5, dateRange) {
  return axios.get('/api/dev/customers', {
    params: {
      last_n: last_n,
      begin: dateRange[0].toISOString(),
      end: dateRange[1].toISOString()
    },
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(response => {
    const data = response.data;
    console.log(data);

    let rows = [];

    for (let i = 0; i < data.length; i++) {
      rows.push(createData(
        data[i]["ID"],
        data[i]["Date"],
        data[i]["Country"],
        data[i]["Translated Comment"],
        data[i]["NPS"]
      ));
    }

    return rows;
  })
  .catch(error => {
    console.error(error);
    
  });
}

export function getPieChartExampleData(dateRange, name) {
  return axios.get('/api/dev/data/topicsPieChart', {
    params: {
      begin: dateRange[0].toISOString(),
      end: dateRange[1].toISOString(),
      name: name
    },
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(response => {
    const data = response.data;
    const pieChartObjects = data['Country Pie Chart'];
    console.log(pieChartObjects);

    if (!pieChartObjects) {
      return [{ "id": 1, "label": "NoCountry", "value": 0 }];
    }

    return pieChartObjects;
  })
  .catch(error => {
    console.error(error);
    
  });
}

export function getExampleTopics(dateRange = dateRange, n = 6) {
  return axios.get('/api/dev/data/topics', {
    params: {
      begin: dateRange[0].toISOString(),
      end: dateRange[1].toISOString(),
      n: n
    },
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(response => {
    const data = response.data;

    const topicObjects = data['Topic Objects'];

    if (!topicObjects) {
      let topics = [
        {
          'name': null,
          'details': {
            'positive': 0,
            'negative': 0,
            'neutral': 0,
            'amount': 0,
            'nps': 0
          },
          'comments': [],
          'sentiments': []
        }
      ];

      console.log(topics);
      return topics;
    }

    console.log("DATA");
    console.log(topicObjects);
    return topicObjects;
  })
  .catch(error => {
    console.error(error);
    
  });
}



export function getExamplePieChartData(dateRange) {
  return axios.get('/api/dev/data/piechart', {
    params: {
      begin: dateRange[0].toISOString(),
      end: dateRange[1].toISOString()
    },
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(response => {
    const data = response.data;
    console.log(data);

    let pieChartData = {
      countries: [],
      counts: []
    };

    for (let i = 0; i < data.length; i++) {
      pieChartData.countries.push(data[i].Country);
      pieChartData.counts.push(data[i].Count);
    }

    return pieChartData;
  })
  .catch(error => {
    console.error(error);
    return { countries: [], counts: [] }; 
    
  });
}
