import React, { useState, useEffect, useRef } from 'react';

export const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    console.log(sectionElement);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      console.log(targetScroll);
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      setTimeout(function () {
        window.scrollTo({
          top: targetScroll,
          behavior: 'smooth',
        });
      },2);
    }
  };