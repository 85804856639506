import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Link } from '@mui/material';
import { scrollToSection } from './utils';

const content = {
  en: {
    sectionTitle: 'Pricing',
    sectionDescription: 'ᴉnsights follows a tiered subscription model. One token means one customer comment. Pay for what your team needs and switch plans easily to keep up with your business needs.',
    customTitle: 'Custom',
    customDescription: 'None of our subscription models fits your needs? Contact us!',
    tiers: [
      {
        title: 'Free Starter',
        price: '0',
        description: [
          'Single user',
          '200 tokens per month',
          'Totally Free',
        ],
        buttonText: 'Sign up for free',
        buttonVariant: 'outlined',
      },
      {
        title: 'Professional',
        subheader: 'Ideal for small teams',
        price: '250',
        description: [
          'For teams of up to 5',
          '30K tokens per team',
          '10 GB of shared storage',
        ],
        buttonText: 'Start now',
        buttonVariant: 'contained',
      },
      {
        title: 'Enterprise',
        price: '1000',
        description: [
          'Up to 20 users',
          '200K tokens',
          '100 GB of Storage',
        ],
        buttonText: 'Contact us',
        buttonVariant: 'outlined',
      },
    ],
  },
  de: {
    sectionTitle: 'Preise',
    sectionDescription: 'ᴉnsights folgt einem gestuften Abonnementmodell. Ein Token bedeutet ein Kundenkommentar. Zahlen Sie für das, was Ihr Team braucht, und wechseln Sie problemlos die Pläne, um mit den Bedürfnissen Ihres Unternehmens Schritt zu halten.',
    customTitle: 'Individuell',
    customDescription: 'Keines unserer Abonnementmodelle passt zu Ihren Bedürfnissen? Kontaktieren Sie uns!',
    tiers: [
      {
        title: 'Kostenloser Start',
        price: '0',
        description: [
          'Einzelner Benutzer',
          '200 Tokens pro Monat',
          'Völlig kostenlos',
        ],
        buttonText: 'Kostenlos anmelden',
        buttonVariant: 'outlined',
      },
      {
        title: 'Professionell',
        subheader: 'Ideal für kleine Teams',
        price: '250',
        description: [
          'Für Teams bis zu 5 Personen',
          '30K Tokens pro Team',
          '10 GB gemeinsamer Speicherplatz',
        ],
        buttonText: 'Jetzt starten',
        buttonVariant: 'contained',
      },
      {
        title: 'Unternehmen',
        price: '1000',
        description: [
          'Bis zu 20 Benutzer',
          '200K Tokens',
          '100 GB Speicherplatz',
        ],
        buttonText: 'Kontaktieren Sie uns',
        buttonVariant: 'outlined',
      },
    ],
  },
};

export default function Pricing({ language }) {
  const { sectionTitle, sectionDescription, customTitle, customDescription, tiers } = content[language];

  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          {sectionTitle}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {sectionDescription}
        </Typography>
      </Box>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {tiers.map((tier) => (
          <Grid
            item
            key={tier.title}
            xs={12}
            sm={tier.title === 'Enterprise' ? 12 : 6}
            md={4}
          >
            <Card
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                border: tier.title === 'Professional' || tier.title === 'Professionell' ? '1px solid' : undefined,
                borderColor:
                  tier.title === 'Professional' || tier.title === 'Professionell' ? 'primary.main' : undefined,
                background:
                  (tier.title === 'Professional' || tier.title === 'Professionell')
                    ? 'linear-gradient(#033363, #021F3B)'
                    : undefined,
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    mb: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    color:
                    (tier.title === 'Professional' || tier.title === 'Professionell') ? 'primary.contrastText' : '',
                  }}
                >
                  <Typography component="h3" variant="h6">
                    {tier.title}
                  </Typography>
                  {tier.title === 'Professional' && (
                    <Chip
                      icon={<AutoAwesomeIcon />}
                      label={tier.subheader}
                      size="small"
                      sx={{
                        background: (theme) =>
                          theme.palette.mode === 'light' ? '' : 'none',
                        backgroundColor: 'primary.contrastText',
                        '& .MuiChip-label': {
                          color: 'primary.dark',
                        },
                        '& .MuiChip-icon': {
                          color: 'primary.dark',
                        },
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'baseline',
                    color:
                      (tier.title === 'Professional' || tier.title === 'Professionell') 
                        ? 'primary.contrastText'
                        : undefined,
                  }}
                >
                  <Typography component="h3" variant="h2">
                    {tier.price}€
                  </Typography>
                  <Typography component="h3" variant="h6">
                    &nbsp; per month
                  </Typography>
                </Box>
                <Divider
                  sx={{
                    my: 2,
                    opacity: 0.2,
                    borderColor: 'grey.500',
                  }}
                />
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{
                      py: 1,
                      display: 'flex',
                      gap: 1.5,
                      alignItems: 'center',
                    }}
                  >
                    <CheckCircleRoundedIcon
                      sx={{
                        width: 20,
                        color:
                         (tier.title === 'Professional' || tier.title === 'Professionell')
                            ? 'primary.light'
                            : 'primary.main',
                      }}
                    />
                    <Typography
                      component="text"
                      variant="subtitle2"
                      sx={{
                        color:
                         (tier.title === 'Professional' || tier.title === 'Professionell')
                            ? 'primary.contrastText'
                            : undefined,
                      }}
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={tier.buttonVariant}
                  component="a"
                  onClick={() => scrollToSection('contact')}
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h3" variant="h4" color="text.primary">
          {customTitle}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {customDescription}
        </Typography>
      </Box>
    </Container>
  );
}
