// SeedTopic.js
import React from 'react';
import { Box, FormControl, Grid, TextField, Typography, Divider } from '@mui/material';

function SeedTopic({ title, description, setTopicTitle, setTopicDescription, index }) {
    return (
        <Box sx={{ padding: 2, borderRadius: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" fontWeight="bold">
                        Seed Topic {index + 1}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            id={`topic_title_${index}`}
                            label="Topic Title"
                            placeholder="Enter topic title"
                            value={title}
                            variant="outlined"
                            onChange={(e) => setTopicTitle(index, e.target.value)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            id={`topic_description_${index}`}
                            label="Topic Description"
                            placeholder="Enter topic description"
                            value={description}
                            variant="outlined"
                            multiline
                            minRows={2}
                            onChange={(e) => setTopicDescription(index, e.target.value)}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    );
}

export default SeedTopic;
