import * as React from 'react';
import { useTheme } from '@emotion/react';
import { ThemeProvider } from '@emotion/react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { MyBarChart } from './Visualization';
import { Options } from './Options';
import Chart from './Chart';
import Comments from './Comments';

export default function Dashboard() {
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      {/* Use maxWidth="lg" for better mobile compatibility */}
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Grid container sx={{ mb: 4, mt: 4 }} alignItems="center">
          <Grid item xs>
            <Typography variant="h3" sx={{ fontWeight: "bold", marginBottom: "5px" }}>
              Dashboard
            </Typography>
          </Grid>
          <Grid item>
            <Options />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          {/* Bar Chart */}
          <Grid item xs={12} sm={6} md={6}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 350 }}>
              <MyBarChart />
            </Paper>
          </Grid>

          {/* Chart */}
          <Grid item xs={12} sm={6} md={6}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 350 }}>
              <Chart />
            </Paper>
          </Grid>

          {/* Recent comments */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <Comments />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
