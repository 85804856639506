import React, { useState } from 'react';
import axios from 'axios'; // Make sure to import axios
import { ThemeProvider, Container, Grid, TextField, Button, List, ListItem, Typography, Paper, Card, CardContent } from '@mui/material';
import Conversation from './Conversation';
import { Options } from './Options';
import { useTheme } from '@emotion/react';

export default function Reports() {

    const theme = useTheme();
  
    return (
        <ThemeProvider theme={theme}>
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>

        <Grid item xs={12} lg={12}>
            <h1 style={{ marginTop: "0px" }}>
            Chat 
            </h1>
        </Grid>

        <Grid container spacing={3}>

            <Grid item xs={12} lg={12}>
            <Options />
            </Grid>

            <Grid item xs={12}>
            <Conversation />
            </Grid>

        </Grid>
        </Container>
        </ThemeProvider>
    )};


