import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import axios from 'axios';
import { DateRangeContext } from './DateRangeContext';

// Expanded comment component
function ExpandComment({ comment, collapsedLength = 5 }) {
  const [expanded, setExpanded] = useState(false);

  if (!comment) {
    return <div>No comment available.</div>;
  }

  return (
    <div onClick={() => setExpanded(!expanded)}>
      {expanded ? comment : comment.slice(0, collapsedLength) + " "}
      {expanded ? null : <Link color="primary">{"..."}</Link>}
    </div>
  );
}

export default function Comments() {
  const [commentCount, setCommentCount] = useState(5);
  const [comments, setComments] = useState([]);
  const { dateRange, selectedColumns, selectedFilters  } = useContext(DateRangeContext);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await axios.get('/api/dev/customers', {
          params: {
            last_n: commentCount,
            begin: dateRange[0].toISOString(),
            end: dateRange[1].toISOString(),
            selectedColumns: selectedColumns,  
            selectedFilters: selectedFilters   
          },
          headers: {
            'Content-Type': 'application/json'
          }
        });
        setComments(response.data);
        console.log("Comments:");
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchComments();
  }, [commentCount, dateRange, selectedColumns, selectedFilters]);

  const handleSeeMore = () => {
    setCommentCount(prevCount => prevCount + 5);
  };

  const handleCollapse = () => {
    setCommentCount(5);
  };

  return (
    <React.Fragment>
      <Title>Recent Comments</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Country</TableCell>
            <TableCell>Comment</TableCell>
            <TableCell>Sentiment</TableCell>
            <TableCell>Topic</TableCell>
            <TableCell>Subtopic</TableCell>
            <TableCell align="right">NPS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            comments.map((row) => (
              <TableRow key={row.ID}>
                <TableCell>{row.ID}</TableCell>
                <TableCell>{row.Date}</TableCell>
                <TableCell>{row.Country}</TableCell>
                <TableCell>
                  <ExpandComment comment={row.Comment} collapsedLength={300} />
                </TableCell>
                <TableCell>{row.Sentiment}</TableCell>
                <TableCell>{row.Subject}</TableCell>
                <TableCell>{row['Underlying Subject']}</TableCell>
                <TableCell align="right">{row.NPS}</TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>

      <Link color="primary" href="#" onClick={handleSeeMore} sx={{ mt: 3 }}>
        See more comments
      </Link>

      <Link color="primary" href="#" onClick={handleCollapse} sx={{ mt: 3 }}>
        Collapse
      </Link>
    </React.Fragment>
  );
}
