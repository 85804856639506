import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import NavigationBarLogin from './NavigationBarLogin';

function Confirmation() {
    const { userId } = useParams();
    const [message, setMessage] = useState('');

    useEffect(() => {
        const confirmUser = async () => {
            try {
                const response = await axios.get(`/api/confirm/${userId}`);
                setMessage(response.data.message);
            } catch (error) {
                setMessage(error.response.data.error || "An error occurred while confirming your account.");
            }
        };

        confirmUser();
    }, [userId]);

    return (
        <>
        <NavigationBarLogin/>
        <Container maxWidth="xl" sx={{ mt: 20, mb: 4, padding: 2}}>
            <Paper>
            <Typography variant="h4" align="center" sx={{ mt: 4, mb: 4, padding: 2}} style={{ flexGrow: 1, color: "black" }}>
            Account Confirmation
            </Typography>
            <Typography variant="h6" align="center" sx={{ mt: 4, mb: 4, padding: 2 }} style={{ flexGrow: 1, color: "black" }}>
            {message}
            </Typography>
            </Paper>
        </Container>
        </>
    );
}

export default Confirmation;
