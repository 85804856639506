import * as React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Chart from './Chart';
import Comments from './Comments';
import { useTheme } from '@emotion/react';
import { ThemeProvider } from '@mui/material';
import { Options } from './Options';
import Summary from './Summary';

export default function Reports() {

  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl" sx={{ mt: 2, mb: 4 }}>
      <Grid container sx={{ mb: 4, mt: 4 }} alignItems="center">
        <Grid item xs>
          <Typography variant="h3" sx={{ fontWeight: "bold", marginTop: "5px" }}>
            Reports
          </Typography>
        </Grid>
        <Grid item>
          <Options />
        </Grid>
      </Grid>

        <Grid container spacing={3}>

          <Grid item xs={12}>
            <Summary />
          </Grid>

        </Grid>
      </Container>
    </ThemeProvider>
    );
}
