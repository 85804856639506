import React from 'react';
import { Box, Container } from '@mui/material';
import NavigationBarLogin from '../../dashboard/NavigationBarLogin';

const Imprint = () => {
  return (
    <>
      <Container
        sx={{
          typography: 'body1',
          mt: 10
        }}
      >
        <NavigationBarLogin />
        <Box>
          <h2><strong>Imprint</strong></h2>
          <h3>Information according to § 5 TMG</h3>

          <p><strong>Insights AI</strong></p>
          <p>Marc Herlitz, Tim Oeser, and Linus Rimpl<br />
            Bauerstraße 38<br />
            80796 München<br />
            Germany
          </p>
          <p>Email: <a href="mailto:info@insights-ai.net">info@insights-ai.net</a></p>

          <h3>Disclaimer</h3>
          <h4>Liability for Content</h4>
          <p>As a service provider, we are responsible for our own content on these pages in accordance with § 7 Abs.1 TMG under general law. However, according to §§ 8 to 10 TMG, we are not obligated as a service provider to monitor transmitted or stored external information or to investigate circumstances that indicate illegal activity.</p>

          <h4>Liability for Links</h4>
          <p>Our offer contains links to external websites of third parties, on whose contents we have no influence. Therefore, we cannot assume any liability for these external contents. The respective provider or operator of the pages is always responsible for the contents of the linked pages.</p>
        </Box>
      </Container>
    </>
  );
};

export default Imprint;
