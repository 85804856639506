import React, { useState, useContext } from 'react';
import axios from 'axios'; // Make sure to import axios
import { Container, TextField, Button, List, ListItem, Typography, Paper, Card, CardContent } from '@mui/material';
import { DateRangeContext } from './DateRangeContext';


function Conversation() {
    const initialMessage = { message: "Welcome to the Insight Chat! Ask any Question below regarding the data you provided!", isUser: false, timestamp: new Date().toLocaleTimeString() };
    const [message, setMessage] = useState('');
    const [chat, setChat] = useState([initialMessage]);
    const [errorMessage, setErrorMessage] = useState(''); // Add state for error messages

    const { dateRange, setDateRange } = useContext(DateRangeContext);
  
    const sendMessage = async () => {
        const token = localStorage.getItem('token');
        console.log("test");
      
        if (!message) {
          setErrorMessage("Error: No Message inserted!");
          return; // Stop the process if no message
        }
      
        // Ensure dateRange is properly scoped or defined outside this function
        const queryString = `begin=${dateRange[0].toISOString()}&end=${dateRange[1].toISOString()}`;
        
        try {
          const response = await fetch(`/dev/chat?${queryString}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token // Assuming your server requires an Authorization header
            },
            body: JSON.stringify({
              message: message,
            })
          });
      
          if (!response.ok) {
            // If the server response was not ok, throw an error with the response status
            throw new Error(`HTTP error! status: ${response.status}`);
          } else {
            const data = await response.json();
            setErrorMessage('');
            const timestamp = new Date().toLocaleTimeString();
            // Assuming `data.response` is the bot's response. Adjust according to your API response structure
            setChat([...chat, { message, isUser: true, timestamp }, { message: data.response, isUser: false, timestamp }]);
            setMessage('');
          }
        } catch (error) {
          console.log(error);
          // Handle any errors that occurred during fetch
          setErrorMessage(`Error: ${error.message || "An error occurred during the chatting."}`);
        }
      };
      
    // Optional: Display error messages to the user
    const renderErrorMessage = () => {
      if (errorMessage) {
        return <Typography color="error">{errorMessage}</Typography>;
      }
    };
  
    return (
      <Container maxWidth="">
        <Paper elevation={3} style={{padding: '20px', marginTop: '20px' }}>
          {renderErrorMessage()}
          <List>
            {chat.map((chatMessage, index) => (
              <ListItem key={index} alignItems="flex-start">
                <Card style={{ width: '100%', backgroundColor: chatMessage.isUser ? '#e0f7fa' : '#f1f8e9' }}>
                  <CardContent>
                    <Typography color="textSecondary" gutterBottom>
                      {chatMessage.isUser ? 'You' : 'Insights Chat'} - {chatMessage.timestamp}
                    </Typography>
                    <Typography variant="body2" component="p" sx={{fontSize: '22px'}}>
                      {chatMessage.message}
                    </Typography>
                  </CardContent>
                </Card>
              </ListItem>
            ))}
          </List>
          <TextField
            label="Type your message"
            fullWidth
            margin="normal"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
          />
          <Button variant="contained" color="primary" onClick={sendMessage}>Send</Button>
        </Paper>
      </Container>
    );
  }
  
  export default Conversation;
  