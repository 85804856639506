import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';

// Create the context
export const SubmitContext = createContext();

// Provider component
export const SubmitProvider = ({ children }) => {
    const [isSubmitted, setIsSubmitted] = useState(false);

    // Function to toggle the submit state
    const triggerSubmit = useCallback(() => {
        setIsSubmitted((prev) => !prev); // Toggle to trigger useEffects
    }, []);

    // Reset isSubmitted after it's been set to true
    useEffect(() => {
        if (isSubmitted) {
            // Optionally reset isSubmitted back to false after a delay
            // to allow for re-triggering if needed
            const timer = setTimeout(() => setIsSubmitted(false), 500); // 500 ms delay
            return () => clearTimeout(timer);
        }
    }, [isSubmitted]);

    return (
        <SubmitContext.Provider value={{ isSubmitted, triggerSubmit }}>
            {children}
        </SubmitContext.Provider>
    );
};

