import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InsightsIcon from '@mui/icons-material/Insights';
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';

const content = {
  en: {
    sectionTitle: 'Product features',
    items: [
      {
        icon: <DashboardIcon />,
        title: 'Dashboard',
        description:
          'Unlock powerful insights with our enhanced Dashboard, featuring tools like stacked bar charts, NPS moving averages, and detailed comment analysis for clear, actionable decisions.',
        imageLight: 'url("../images/dashboard_landing.png")',
        imageDark: 'url("/static/images/templates/templates-images/mobile-dark.PNG")',
      },
      {
        icon: <InsightsIcon />,
        title: 'ᴉnsights Engine',
        description:
          'Transform your data into powerful insights with our advanced analysis tool, turning complex feedback into clear, actionable intelligence that drives smarter decisions.',
        imageLight: 'url("../images/topic.png")',
        imageDark: 'url("/static/images/templates/templates-images/mobile-dark.PNG")',
      },
      {
        icon: <DevicesRoundedIcon />,
        title: 'Report Generation',
        description:
          'Instantly unlock the value of customer feedback with our dynamic reports, delivering sharp, actionable summaries that empower leaders to make impactful decisions fast.',
        imageLight: 'url("../images/report_landing.png")',
        imageDark: 'url("/static/images/templates/templates-images/devices-dark.PNG")',
      },
    ],
  },
  de: {
    sectionTitle: 'Produkteigenschaften',
    items: [
      {
        icon: <DashboardIcon />,
        title: 'Dashboard',
        description:
          'Entfesseln Sie leistungsstarke Einblicke mit unserem erweiterten Dashboard, das Werkzeuge wie gestapelte Balkendiagramme, NPS-Gleitdurchschnitte und detaillierte Kommentaranalysen für klare, umsetzbare Entscheidungen bietet.',
        imageLight: 'url("../images/dashboard_landing.png")',
        imageDark: 'url("/static/images/templates/templates-images/mobile-dark.PNG")',
      },
      {
        icon: <InsightsIcon />,
        title: 'ᴉnsights Engine',
        description:
          'Verwandeln Sie Ihre Daten in wertvolle Erkenntnisse mit unserem fortschrittlichen Analysetool, das komplexes Feedback in klare, umsetzbare Intelligenz umwandelt und so klügere Entscheidungen ermöglicht.',
        imageLight: 'url("../images/topic.png")',
        imageDark: 'url("/static/images/templates/templates-images/mobile-dark.PNG")',
      },
      {
        icon: <DevicesRoundedIcon />,
        title: 'Berichtsgenerierung',
        description:
          'Schalten Sie sofort den Wert von Kundenfeedback mit unseren dynamischen Berichten frei, die prägnante, umsetzbare Zusammenfassungen liefern und Führungskräften ermöglichen, schnell wirkungsvolle Entscheidungen zu treffen.',
        imageLight: 'url("../images/report_landing.png")',
        imageDark: 'url("/static/images/templates/templates-images/devices-dark.PNG")',
      },
    ],
  },
};

export default function Features({ language }) {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);
  const items = content[language].items;

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature = items[selectedItemIndex];

  return (
    <Container id="features" sx={{ mt: 8, mb: 8, py: { xs: 4, sm: 8 }, bgcolor: 'white', color: 'black', borderRadius: '15px'}}>
      <Typography component="h2" variant="h4" color="text.primary" textAlign="center" sx={{ mb: 4 }}>
        {content[language].sectionTitle}
      </Typography>
      
      {/* Clickable Items */}
      <Grid container spacing={2} justifyContent="center" sx={{ mb: 4 }}>
        {items.map(({ icon, title }, index) => (
          <Grid item key={index}>
            <Chip
              icon={icon}
              label={title}
              onClick={() => handleItemClick(index)}
              sx={{
                fontSize: '1.1rem',
                cursor: 'pointer',
                backgroundColor: selectedItemIndex === index ? 'primary.main' : 'grey.500',
                color: selectedItemIndex === index ? '#fff' : 'text.primary',
              }}
            />
          </Grid>
        ))}
      </Grid>
      
      {/* Text and Images Section */}
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} textAlign="center">
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography variant="body1" sx={{ flexGrow: 1, textAlign: 'center', fontSize: 20 }}>
              {selectedFeature.description}
            </Typography>    
          </Box>
        </Grid>
        <Grid item xs={12}>
        <Card
          sx={{
            width: '100%',
            height: { xs: '300px', sm: '400px', md: '500px' }, // Responsive height
            backgroundSize: 'contain', // Ensures the entire image fits within the box
            backgroundRepeat: 'no-repeat', // No repeated images
            backgroundPosition: 'center', // Centers the image
            backgroundImage: (theme) =>
              theme.palette.mode === 'light'
                ? selectedFeature.imageLight
                : selectedFeature.imageDark,
            borderRadius: '10px',
            border: '1px solid #ccc', // Optional: Add a border to make the card more distinct
          }}
        />
      </Grid>

      </Grid>
    </Container>
  );
}
