import React, { useState, useEffect, useContext } from 'react';
import {
  TextField, MenuItem, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Paper, Typography, CssBaseline, Grid, Chip, IconButton
} from '@mui/material';
import { DateRangeContext } from './DateRangeContext';
import axios from 'axios';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const Filter = (props) => {
  const [data, setData] = useState([]);
  const [filterOptions, setFilterOptions] = useState({});
  const { dateRange, selectedColumns, selectedFilters, updateFilter } = useContext(DateRangeContext);
  const [filteredData, setFilteredData] = useState([]);
  const [currentColumn, setCurrentColumn] = useState('');
  const [currentFilter, setCurrentFilter] = useState('');

  const columnNameMapping = {
    "Subject": "Topic",
    "Underlying Subject": "Subtopic"
  };

  const reverseColumnNameMapping = {
    "Topic": "Subject",
    "Subtopic": "Underlying Subject"
  };

  // Fetch unfiltered data
  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await axios.get('/api/dev/customers', {
          params: {
            last_n: 50,
            begin: dateRange[0].toISOString(),
            end: dateRange[1].toISOString()
          },
          headers: {
            'Content-Type': 'application/json'
          }
        });
        setData(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchComments();
  }, [dateRange]);

  const filterableColumns = ['Topic', 'Sentiment', 'Subtopic', 'NPS', 'Country'];

  const allColumns = data.length > 0 ? Object.keys(data[0]) : [];

  // Apply filters on the frontend
  useEffect(() => {
    let filtered = data;

    if (selectedColumns.length > 0 && selectedFilters.length > 0) {
      // Build a mapping of columns to their filter values
      const columnFilterMap = {};
      selectedColumns.forEach((col, index) => {
        const filterValue = selectedFilters[index];
        if (filterValue) {
          if (!columnFilterMap[col]) {
            columnFilterMap[col] = [];
          }
          columnFilterMap[col].push(filterValue);
        }
      });

      // Apply filters for each column
      Object.keys(columnFilterMap).forEach((col) => {
        const filters = columnFilterMap[col];
        filtered = filtered.filter((d) => filters.includes(d[col]));
      });
    }

    setFilteredData(filtered);
  }, [selectedColumns, selectedFilters, data]);

  const handleColumnChange = (event) => {
    const userFriendlyColumn = event.target.value;
    const column = reverseColumnNameMapping[userFriendlyColumn] || userFriendlyColumn;
    setCurrentColumn(column);
    const options = Array.from(new Set(data.map((d) => d[column])));

    // Exclude already selected filters for this column
    const appliedFiltersForColumn = selectedColumns
      .map((col, index) => (col === column ? selectedFilters[index] : null))
      .filter((filterValue) => filterValue !== null);

    const availableOptions = options.filter((option) => !appliedFiltersForColumn.includes(option));

    setFilterOptions((prevOptions) => ({ ...prevOptions, [column]: availableOptions }));
    setCurrentFilter('');
  };

  const handleCategoryChange = (event) => {
    setCurrentFilter(event.target.value);
  };

  const handleAddFilter = () => {
    if (currentColumn && currentFilter) {
      // Allow adding the same column multiple times
      const newselectedColumns = [...selectedColumns, currentColumn];
      const newselectedFilters = [...selectedFilters, currentFilter];
      updateFilter(newselectedColumns, newselectedFilters);
      setCurrentColumn('');
      setCurrentFilter('');
    }
  };

  const handleRemoveFilter = (index) => {
    const newselectedColumns = selectedColumns.filter((_, i) => i !== index);
    const newselectedFilters = selectedFilters.filter((_, i) => i !== index);

    updateFilter(newselectedColumns, newselectedFilters);
  };

  const handleClose = () => {
    props.onClose(); // Close the dialog or perform any action to close the component
  };

  const displayData = filteredData;

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <TextField
            fullWidth
            select
            label="Column"
            value={currentColumn ? columnNameMapping[currentColumn] || currentColumn : ''}
            onChange={handleColumnChange}
            margin="normal"
          >
            {filterableColumns.map((col) => (
              <MenuItem key={col} value={col}>{col}</MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={5}>
          <TextField
            fullWidth
            select
            label="Filter"
            value={currentFilter}
            onChange={handleCategoryChange}
            margin="normal"
            disabled={!currentColumn}
          >
            {filterOptions[currentColumn]?.length > 0 ? (
              filterOptions[currentColumn].map((option) => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))
            ) : (
              <MenuItem disabled value="">
                No options available
              </MenuItem>
            )}
          </TextField>
        </Grid>
        <Grid item xs={2}>
          <IconButton
            color="primary"
            onClick={handleAddFilter}
            disabled={!currentColumn || !currentFilter}
            style={{ marginTop: '16px' }}
          >
            <AddCircleOutlineIcon sx={{ mt: '8px' }} />
          </IconButton>
        </Grid>
      </Grid>
      <Box mt={2}>
        <Typography variant="h6">Current Filters:</Typography>
        {selectedColumns.length > 0 ? (
          selectedColumns.map((column, index) => (
            <Chip
              key={`${column}-${selectedFilters[index]}-${index}`}
              label={`${columnNameMapping[column] || column}: ${selectedFilters[index] || 'Any'}`}
              onDelete={() => handleRemoveFilter(index)}
              style={{ marginRight: '8px', marginBottom: '8px' }}
            />
          ))
        ) : (
          <Typography variant="body1" color="textSecondary">
            No Filters set yet
          </Typography>
        )}
      </Box>
      <Box mt={4}>
        <CssBaseline />
        <Typography variant="h5" gutterBottom>
          Data Preview
        </Typography>
        <TableContainer component={Paper} style={{ maxHeight: 400, overflow: 'auto' }}>
          <Table stickyHeader>
            <TableHead style={{ backgroundColor: "grey" }}>
              <TableRow>
                {allColumns.map((column) => (
                  <TableCell key={column} style={{ color: "#000000", fontWeight: "bold" }}>
                    {columnNameMapping[column] || column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {displayData.map((row, index) => (
                <TableRow key={index} style={{ backgroundColor: index % 2 ? "#f9f9f9" : "inherit" }}>
                  {allColumns.map((column) => (
                    <TableCell key={column}>{row[column]}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box mt={4} display="flex" justifyContent="center">
          <Button variant="outlined" onClick={handleClose} style={{ width: '150px' }}>
            Close
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default Filter;
