import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CenterFocusStrongRoundedIcon from '@mui/icons-material/CenterFocusStrongRounded';
import TouchAppRoundedIcon from '@mui/icons-material/TouchAppRounded';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';

const content = {
  en: {
    sectionTitle: 'Highlights',
    sectionDescription: 'What makes us stand out.',
    items: [
      {
        icon: <CenterFocusStrongRoundedIcon fontSize="large" />,
        title: 'Customer-feedback focused',
        description:
          'Our product was developed from the ground up to work with customer feedback data.',
      },
      {
        icon: <TouchAppRoundedIcon fontSize="large" />,
        title: 'Ease of use',
        description:
          'Our user experience is designed to be used by everyone, from marketing to management.',
      },
      {
        icon: <AutoAwesomeRoundedIcon fontSize="large" />,
        title: 'State of the art language modelling',
        description:
          'We deploy state of the art language models such as GPT-4, Gemini and Mixtral.',
      },
      {
        icon: <QueryStatsRoundedIcon fontSize="large" />,
        title: 'Insights at a glance',
        description:
          'Report generation allows users to understand their customers better, faster.',
      },
      {
        icon: <SearchRoundedIcon fontSize="large" />,
        title: 'In-depth understanding',
        description:
          'Explore your customer feedback in as much detail as you like, from general topics to individual comments.',
      },
      {
        icon: <SupportAgentRoundedIcon fontSize="large" />,
        title: 'Customer focused',
        description:
          'Our team is actively working with customers to improve and tailor insights to their needs.',
      },
    ],
  },
  de: {
    sectionTitle: 'Höhepunkte',
    sectionDescription: 'Was uns auszeichnet.',
    items: [
      {
        icon: <CenterFocusStrongRoundedIcon fontSize="large" />,
        title: 'Kundenfeedback-fokussiert',
        description:
          'Unser Produkt wurde von Grund auf so entwickelt, dass es mit Kundendaten arbeitet.',
      },
      {
        icon: <TouchAppRoundedIcon fontSize="large" />,
        title: 'Benutzerfreundlichkeit',
        description:
          'Unsere Benutzeroberfläche wurde so gestaltet, dass sie von jedem verwendet werden kann, vom Marketing bis zum Management.',
      },
      {
        icon: <AutoAwesomeRoundedIcon fontSize="large" />,
        title: 'Moderne Sprachmodelle',
        description:
          'Wir setzen modernste Sprachmodelle wie GPT-4, Gemini und Mixtral ein.',
      },
      {
        icon: <QueryStatsRoundedIcon fontSize="large" />,
        title: 'Einblicke auf einen Blick',
        description:
          'Die Berichtserstellung ermöglicht es den Nutzern, ihre Kunden besser und schneller zu verstehen.',
      },
      {
        icon: <SearchRoundedIcon fontSize="large" />,
        title: 'Tiefes Verständnis',
        description:
          'Erkunden Sie Ihr Kundenfeedback so detailliert, wie Sie möchten, von allgemeinen Themen bis hin zu einzelnen Kommentaren.',
      },
      {
        icon: <SupportAgentRoundedIcon fontSize="large" />,
        title: 'Kundenorientiert',
        description:
          'Unser Team arbeitet aktiv mit Kunden zusammen, um Einblicke zu verbessern und an ihre Bedürfnisse anzupassen.',
      },
    ],
  },
};

export default function Highlights({ language }) {
  const { sectionTitle, sectionDescription, items } = content[language];

  return (
    <Box
      id="highlights"
      maxWidth="lg"
      sx={{
        mt: { xs: 4, sm: 8 },
        mb: { xs: 4, sm: 8 },
        pt: { xs: 4, sm: 8 },
        pb: { xs: 6, sm: 12 },
        px: { xs: 2, sm: 6 },
        color: 'white',
        bgcolor: '#06090a',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0 auto',
      }}
    >
      <Box sx={{ textAlign: 'center', mb: 6 }}>
        <Typography component="h2" variant="h3" sx={{ mb: 1 }}>
          {sectionTitle}
        </Typography>
        <Typography variant="body1" sx={{ color: 'grey.400', fontSize: '1.1rem' }}>
          {sectionDescription}
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {items.slice(0, 4).map((item, index) => ( // Display only 4 items for brevity
          <Grid item xs={12} sm={6} key={index}>
            <Card
              sx={{
                p: 4,
                height: '100%',
                border: '1px solid',
                borderColor: 'grey.800',
                backgroundColor: 'grey.900',
                color: 'inherit',
                textAlign: 'center',
              }}
            >
              <Box sx={{ mb: 2 }}>{item.icon}</Box>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                {item.title}
              </Typography>
              <Typography variant="body1" sx={{ color: 'grey.300' }}>
                {item.description}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
